import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const ProjectImg = ({ filename, alt }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1366) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      if (typeof filename === "string") {
        const image = data.images.edges.find((n) => n.node.relativePath.includes(filename));
        if (!image) return null;
        const imageFluid = image.node.childImageSharp.fluid;
        return <Img alt={alt} fluid={imageFluid} />;
      }
      else {
        console.log(filename)
        return <img src={filename[Object.keys(filename)[0]]} alt={alt} style={{ maxWidth: "1366px", width: "100%" }} />
      }
    }}
  />
);

ProjectImg.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
};

export default ProjectImg;
